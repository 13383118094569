import {useEffect, useState} from "react";

export default function CountDownTimer(props){
    // Initial time in seconds (1 hour)
    const [timeRemaining, setTimeRemaining] = useState(props.initialTime);

    useEffect(() => {
        const timerInterval = setInterval(() => {
            setTimeRemaining((prevTime) => {
                if (prevTime === 0) {
                    clearInterval(timerInterval);
                    // Perform actions when the timer reaches zero
                    return 0;
                } else {
                    return prevTime - 1;
                }
            });
        }, 1000);

        // Cleanup the interval when the component unmounts
        return () => clearInterval(timerInterval);
    }, []); // The empty dependency array ensures the effect runs only once on mount

    // Convert seconds to hours, minutes, and seconds
    const days = Math.floor(timeRemaining / 86400);
    // 120%
    const hours = Math.floor((timeRemaining / 3600) % 24);
    const minutes = Math.floor((timeRemaining % 3600) / 60);
    const seconds = timeRemaining % 60;

    return (
        <div>
            <p>Ends in:</p>
            <p>{`${days}d ${hours}h ${minutes}m ${seconds}s`}</p>
        </div>
    );
}