import "../css/bonuthunt-page.css";
import Button from "../components/Button";
import bonusHuntImg from "../assets/img/bonus-hunt-img.png";
import firstPlaceChip from "../assets/img/1st-place.png";
import secondPlaceChip from "../assets/img/2nd-place.png";
import thirdPlaceChip from "../assets/img/3rd-place.png";
import React, {useEffect, useState} from "react";
import {useAuth} from "../AuthProvider";
import tripleChips from "../assets/img/triple-pokerchips.png";
import axios from "axios";
import Loader from "../components/Loader";
import {ToastContainer, toast} from "react-toastify";
import {toast_options} from "../components/toast";


export default function BonusHunt(){
    const buhlockay_api = process.env.REACT_APP_BACKEND_API;

    const {user} = useAuth();
    const [loading, setLoading] = useState(true);
    const [huntInfo, setHuntInfo] = useState(null);
    const [kickName, setKickName] = useState('');
    const [guessedBalance, setGuessedBalance] = useState(null);
    const [secondData, setSecondData] = useState(null);

    const getActiveHunt = async() =>{

        try{
            await axios.get(`${buhlockay_api}/api/common/active-hunt`)
                .then(response =>{
                    setHuntInfo(response.data);
                    return response.data;
                })
                .then(async (huntInfo) =>{
                    await axios.get(`https://bht.bet/api/hf8EXkX0UbkaB4kgF8FzciDVdNTHMLO1/hunts/${huntInfo.hunt_id}`).then((response) =>{
                        setSecondData(response.data);
                    })
                })
                .catch(error =>{
                    if (error.response) {
                        toast.error(error.response.data.msg, toast_options);
                    } else if (error.request) {
                        toast.error(error.request, toast_options);
                    } else {
                        toast.error(error.message, toast_options);
                    }
                    toast.error(error.config,toast_options);
                })
        }
        catch(error){
            console.log(error);
        }
        setLoading(false);
    }


    const guessBalance = (hunt_id, _id, guessed_balance, kick_name) =>{

    axios.post(`${buhlockay_api}/api/common/guess-balance`, {hunt_id: hunt_id, _id: _id, guessed_balance: guessed_balance, kick_name:kick_name}, {
        headers:{"Access-Control-Allow-Methods" : "POST"},
        withCredentials:true})
        .then(response =>{
            toast.success("Your guess has been submitted, wait for results!", toast_options);
        })
        .catch(error =>{
            if (error.response) {
                toast.error(error.response.data.msg, toast_options);
            } else if (error.request) {
                toast.error(error.request, toast_options);
            } else {
                toast.error(error.message, toast_options);
            }
            toast.error(error.config,toast_options);

        })
    }

    const returnWinner = (winner)=>{
        if(winner){
            return(
                <h6 className="found-winner">{winner.kick_name}</h6>
            )
        }

        if(huntInfo.is_enabled){

            return(
                <div>hunt is live, predict now!</div>
            )

        }
        else{
            if(!winner){
                return(
                    <div>-</div>
                )
            }
            return(
                <div>hunt is offline, starting soon!</div>
            )
        }
    }

    const returnPrediction = (winner) =>{
        if(winner){
            return(
                <div>Winners are announced, wait for the next bonus hunt you will get a chance to guess next time!</div>
            )
        }

        if(huntInfo.is_enabled){

            return(
                <form onSubmit={(e)=>{
                    e.preventDefault();
                    guessBalance(huntInfo.hunt_id, huntInfo._id, guessedBalance, kickName);
                }}>
                    <h6>Predict now!</h6>
                    <input
                        onChange={(e) =>
                        {
                            setKickName(e.target.value)}}
                        style={{padding:"8px 10px", marginBottom:"14px"}}
                        placeholder="Kick name"
                        minlength="5"
                    />
                    <input
                        onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                        className="input-number"
                        type="number"
                        min="0"
                        onChange={(e) => setGuessedBalance(e.target.value)}
                        style={{padding:"8px 10px", marginBottom:"14px"}}
                        placeholder="Guess balance"
                    />
                    <Button type="submit">Submit</Button>
                </form>
            )

        }
        else{
            return(
                <div>You can't predict. The hunt is disabled at the moment.</div>
            )
        }

    }

    useEffect(()=>{
        getActiveHunt();
    },[])


    return(
        <div className="bonus-hunt page">


            <div className="bonus-hunt-hero-image container">
                <div className="left-block">
                    <h3 className="heading">
                        Guess & Win
                    </h3>
                    <p>Get a chance to spin free spins, predict the outcome and win big prizes!</p>
                </div>
                <div className="right-block">
                    <img src={bonusHuntImg} alt="bonus hunt hero img"/>
                </div>
            </div>

            <div className="container prediction-wrapper">

                <div className="prediction-box box-styling">

                    {/* 1. check if user is logged in
                    2. check if there is a bhg enabled
                    */}
                    <ToastContainer />
                    {user.username ?
                        huntInfo ?
                            returnPrediction(huntInfo.first_place || huntInfo.second_place || huntInfo.third_place)
                            : <div>not available</div>
                        :
                        <p style={{marginTop:"8px"}}>
                            You must be logged in to predict!</p>
                    }
                </div>

                <div className="bonus-hunt-session">
                    <div className="top-predictors-container">
                        <div className="second-place placement box-styling">
                            <span className="circle-wrapper">
                                <img src={secondPlaceChip} alt="second place chip img"/>
                            </span>
                            {loading && <Loader/>}

                            {
                                !loading &&
                                returnWinner(huntInfo.second_place)
                            }


                        </div>

                        <div className="first-place placement box-styling">
                            <span className="circle-wrapper">
                                <img src={firstPlaceChip} alt="first place chip img"/>
                            </span>
                            {loading && <Loader/>}
                            {
                                !loading &&
                                returnWinner(huntInfo.first_place)
                            }
                        </div>

                        <div className="third-place placement box-styling">
                            <span className="circle-wrapper">
                                <img src={thirdPlaceChip} alt="third place chip img"/>
                            </span>
                            {loading && <Loader/>}
                            {
                                !loading &&
                                returnWinner(huntInfo.third_place)
                            }
                        </div>
                    </div>

                    <div className="active-bonus-hunt-info">
                        <div className="hunt-id box-styling">
                            <h6>
                                {loading && <Loader/>}
                                {!loading && huntInfo ? <div>{huntInfo.hunt_id}</div> : <div>not available</div>}
                            </h6>
                            <p className="text-sm">Hunt ID</p>
                        </div>
                        <div className="hunt-id box-styling">
                            <h6>
                                {loading && <Loader/>}
                                {!loading && secondData ? <div>{secondData.bonuses.length}</div> : <div>not available</div>}
                            </h6>
                            <p className="text-sm"># of bonuses</p>
                        </div>
                        <div className="hunt-id box-styling">
                            <h6>
                                {loading && <Loader/>}
                                {!loading && secondData ? <div>{secondData.info_start_cost}</div> : <div>not available</div>}
                            </h6>
                            <p className="text-sm">Starting Bal.</p>
                        </div>
                        <div className="hunt-id box-styling">
                            <h6> {loading && <Loader/>}
                                {!loading && secondData ? <div>{secondData.info_amount_won}</div> : <div>not available</div>}
                            </h6>
                            <p className="text-sm">Ending Bal.</p>
                        </div>
                        <div className="hunt-id box-styling">
                            <h6>{loading && <Loader/>}
                                {!loading && secondData ? <div>{secondData.info_highest_multi}</div> : <div>not available</div>}
                        </h6>
                            <p className="text-sm">Highest X</p>
                        </div>
                    </div>

                </div>

            </div>

            <div className="about-bonus-hunt container">
                <div className="box-styling hunt-faq">
                    <ul className="faq">
                        <li>
                            <p style={{fontWeight:"700"}}>What is bonus hunt</p>
                            <p style={{marginTop:"10px"}}>Bonus hunt is a collection of bonus buys accumulated from starting balance. During bonus hunt viewers can predict the ending balance.</p>
                        </li>
                        <li style={{marginTop:"30px"}}>
                            <p style={{fontWeight:"700"}}>How to participate</p>
                            <p style={{marginTop:"10px"}}>To be able to participate there must be an active bonus hunt. These happen randomly although most time they are announced in discord. To be able to predict the outcome of one of these you need to
                                <a href="https://discord.gg/buhlockay" target="_blank" rel="noopener noreferrer">
                                    <span className="text-highlight" style={{margin:"0 5px"}}>join discord</span>
                                </a>
                                and submit your prediction on “Guess the balance” to acquire a prize, make sure you are watching that stream!</p>
                        </li>
                    </ul>
                    <img className="triple-chips" src={tripleChips} alt="triple chips"/>
                </div>
                <div className="bonus-games box-styling">
                    <table cellSpacing="0" cellPadding="0">
                        <thead>
                        <tr>
                            <th style={{width:"365px"}}>game</th>
                            <th>base bet</th>
                            <th>x-win</th>
                            <th>win amount</th>
                        </tr>
                        </thead>
                        <tbody>
                            {loading && <Loader/>}
                            {secondData ? secondData.bonuses.map((item, index) =>{
                                    return(
                                        <tr key={index}>
                                            <td>{item.name}</td>
                                            <td style={{color:"#068fff"}}>{item.bet_size}</td>
                                            <td style={{color:"#FFBF00"}}>{item.multiplier ? item.multiplier : "-"}</td>
                                            <td style={{color:"#22C55E"}}>{item.payout}</td>
                                        </tr>
                                )}) : null
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}