// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popup{
    width:100vw;
    height:100vh;
    position: fixed;
    display:flex;
    left:0;
    top:0;
    z-index: 99999;
    justify-content: center;
}
.popup__main{
    position: relative;
    color: white;
    z-index: 99999;
    background: #0f172a;
    max-width:460px;
    border-radius:12px;
    align-self: center;
}
.popup__header, .popup__body, .popup__controls{
    width:100%;
    padding:16px 24px;
}
.popup__header{
    font-weight: bold;
    border-bottom:1px solid #1f2145;
}
.popup__controls{
    display:flex;
    justify-content: center;
    border-top:1px solid #1f2145;
}
.popup__background{
    width:100%;
    height:100%;
    background: rgba(0,0,0,0.75);
    filter: blur(8px);
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
    opacity:0.5;
    position:absolute;
    left:0;
    top:0;
    cursor:pointer;
}`, "",{"version":3,"sources":["webpack://./src/css/popup.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,eAAe;IACf,YAAY;IACZ,MAAM;IACN,KAAK;IACL,cAAc;IACd,uBAAuB;AAC3B;AACA;IACI,kBAAkB;IAClB,YAAY;IACZ,cAAc;IACd,mBAAmB;IACnB,eAAe;IACf,kBAAkB;IAClB,kBAAkB;AACtB;AACA;IACI,UAAU;IACV,iBAAiB;AACrB;AACA;IACI,iBAAiB;IACjB,+BAA+B;AACnC;AACA;IACI,YAAY;IACZ,uBAAuB;IACvB,4BAA4B;AAChC;AACA;IACI,UAAU;IACV,WAAW;IACX,4BAA4B;IAC5B,iBAAiB;IACjB,mCAAmC;IACnC,2BAA2B;IAC3B,WAAW;IACX,iBAAiB;IACjB,MAAM;IACN,KAAK;IACL,cAAc;AAClB","sourcesContent":[".popup{\n    width:100vw;\n    height:100vh;\n    position: fixed;\n    display:flex;\n    left:0;\n    top:0;\n    z-index: 99999;\n    justify-content: center;\n}\n.popup__main{\n    position: relative;\n    color: white;\n    z-index: 99999;\n    background: #0f172a;\n    max-width:460px;\n    border-radius:12px;\n    align-self: center;\n}\n.popup__header, .popup__body, .popup__controls{\n    width:100%;\n    padding:16px 24px;\n}\n.popup__header{\n    font-weight: bold;\n    border-bottom:1px solid #1f2145;\n}\n.popup__controls{\n    display:flex;\n    justify-content: center;\n    border-top:1px solid #1f2145;\n}\n.popup__background{\n    width:100%;\n    height:100%;\n    background: rgba(0,0,0,0.75);\n    filter: blur(8px);\n    -webkit-backdrop-filter: blur(30px);\n    backdrop-filter: blur(30px);\n    opacity:0.5;\n    position:absolute;\n    left:0;\n    top:0;\n    cursor:pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
