// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `button, input[type="submit"], input[type="reset"] {
    background: #0572CC;
    color: var(--white);
    border: none;
    padding: 10px 16px;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    box-shadow: 0px 1px 14.12px 0px #045699;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    grid-column-gap: 6px;
    text-decoration: none;
}
header button, header input[type="submit"], header input[type="reset"]{
    padding:7.22px 16px;
}
button *{
    flex-shrink: 0;
    font-weight:700;
}
button svg{
    width:28px;
    height:28px;
}
button:disabled, button[disabled]{
    background: var(--disabled-btn-bg);
    color: var(--disabled-btn-txt);
    cursor: not-allowed;
}
.error{
    background: var(--red);
    color:var(--white);
    box-shadow: none;
}
.success{
    background: var(--green);
    color: var(--white);
    box-shadow: none;
}`, "",{"version":3,"sources":["webpack://./src/css/Button.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,mBAAmB;IACnB,YAAY;IACZ,kBAAkB;IAClB,aAAa;IACb,eAAe;IACf,gBAAgB;IAChB,uCAAuC;IACvC,kBAAkB;IAClB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,cAAc;IACd,oBAAoB;IACpB,qBAAqB;AACzB;AACA;IACI,mBAAmB;AACvB;AACA;IACI,cAAc;IACd,eAAe;AACnB;AACA;IACI,UAAU;IACV,WAAW;AACf;AACA;IACI,kCAAkC;IAClC,8BAA8B;IAC9B,mBAAmB;AACvB;AACA;IACI,sBAAsB;IACtB,kBAAkB;IAGlB,gBAAgB;AACpB;AACA;IACI,wBAAwB;IACxB,mBAAmB;IAGnB,gBAAgB;AACpB","sourcesContent":["button, input[type=\"submit\"], input[type=\"reset\"] {\n    background: #0572CC;\n    color: var(--white);\n    border: none;\n    padding: 10px 16px;\n    font: inherit;\n    cursor: pointer;\n    outline: inherit;\n    box-shadow: 0px 1px 14.12px 0px #045699;\n    border-radius: 8px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-shrink: 0;\n    grid-column-gap: 6px;\n    text-decoration: none;\n}\nheader button, header input[type=\"submit\"], header input[type=\"reset\"]{\n    padding:7.22px 16px;\n}\nbutton *{\n    flex-shrink: 0;\n    font-weight:700;\n}\nbutton svg{\n    width:28px;\n    height:28px;\n}\nbutton:disabled, button[disabled]{\n    background: var(--disabled-btn-bg);\n    color: var(--disabled-btn-txt);\n    cursor: not-allowed;\n}\n.error{\n    background: var(--red);\n    color:var(--white);\n    -webkit-box-shadow: none;\n    -moz-box-shadow: none;\n    box-shadow: none;\n}\n.success{\n    background: var(--green);\n    color: var(--white);\n    -webkit-box-shadow: none;\n    -moz-box-shadow: none;\n    box-shadow: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
