// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.timer-container{
    width:100%;
    display:flex;
    flex-direction:column;
    padding: 24px;
    align-items: center;
    justify-content: center;
    gap:24px;
}
.time{
    font-size:24px;
}
.dimension{
    font-size:16px;
}
@media (min-width:640px){
    .timer-container{
        flex-direction:row;
    }
}
@media (min-width:1024px){


}
@media (min-width:1280px){

}
@media (min-width:1536px){

}`, "",{"version":3,"sources":["webpack://./src/css/countdowntimer.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,YAAY;IACZ,qBAAqB;IACrB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,QAAQ;AACZ;AACA;IACI,cAAc;AAClB;AACA;IACI,cAAc;AAClB;AACA;IACI;QACI,kBAAkB;IACtB;AACJ;AACA;;;AAGA;AACA;;AAEA;AACA;;AAEA","sourcesContent":[".timer-container{\n    width:100%;\n    display:flex;\n    flex-direction:column;\n    padding: 24px;\n    align-items: center;\n    justify-content: center;\n    gap:24px;\n}\n.time{\n    font-size:24px;\n}\n.dimension{\n    font-size:16px;\n}\n@media (min-width:640px){\n    .timer-container{\n        flex-direction:row;\n    }\n}\n@media (min-width:1024px){\n\n\n}\n@media (min-width:1280px){\n\n}\n@media (min-width:1536px){\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
