import {useAuth} from "../AuthProvider";
import "../css/user-profile.css";
import Button from "../components/Button";
// import { Badge, Button} from '@mantine/core';

export default function UserMeProfile(){
    const { user } = useAuth();
    const auth = useAuth();
    const userRole = (roleNum) =>{
        switch(roleNum){
            case 0:
                return <div>User</div>
            case 1:
                return <div>Mod</div>
            case 2:
                return <div>Admin</div>
            default:
                return "Error"
        }
    }

    return(
        user.username ?
      <div className="userme-profile container" style={{color:"white"}}>
          <div className="profile-container">
              <img className="profile-avatar" src={user.avatar} />
              <div className="user-info">
                  <h6 style={{marginBottom:"8px"}}>{user.username}</h6>
                  {userRole(user.role)}
              </div>
          </div>
          <Button onClick={()=>{
              auth.logOut()
          }}>Log out</Button>
      </div> : <div>You are not logged in</div>
    )

}