// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-page{
    color: var(--white);
    margin-top:50px;
}
.bhgames-table tr{
    cursor:pointer;
    padding:7px 0;
}
`, "",{"version":3,"sources":["webpack://./src/css/admin-page.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,eAAe;AACnB;AACA;IACI,cAAc;IACd,aAAa;AACjB","sourcesContent":[".admin-page{\n    color: var(--white);\n    margin-top:50px;\n}\n.bhgames-table tr{\n    cursor:pointer;\n    padding:7px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
