import "../css/wagers-leaderboard-page.css";
import firstPlaceChip from "../assets/img/1st-place.png";
import secondPlaceChip from "../assets/img/2nd-place.png";
import thirdPlaceChip from "../assets/img/3rd-place.png";
import React, {useEffect, useState} from "react";
import axios from "axios";
import Loader from "../components/Loader";
import {NavLink} from "react-router-dom";
import Button from "../components/Button";
import moment from "moment-timezone";
import roobetLogo from "../assets/img/roobet-logo.png";

export default function LastMonthWagersLeaderboard(){
    const wagers_api = process.env.REACT_APP_BACKEND_LAST_WAGERS;
    const [wagersData, setWagersData] = useState({});
    const [loading, setLoading] = useState(true);
    const [periodStart,setPeriodStart] = useState(null);
    const [periodEnd, setPeriodEnd] = useState(null);
    useEffect(() => {
        const fetchData = async() =>{
            try{
                await axios.get(wagers_api)
                    .then((response) => {
                        let data = response.data[1].wagers_list;
                        setWagersData(data);
                        setPeriodStart(response.data[0].date_info.begin_date);
                        setPeriodEnd(response.data[0].date_info.end_date);
                    });
            } catch(error){
                console.log(error);
            }
            setLoading(false);
        }

        fetchData();
    }, []);

    return(
        <div className="root page wagers-leaderboard">
            <div className="container">
                {loading && <Loader/>}
                {!loading && <div className="top-3 box-styling">
                    <NavLink style={{fontWeight:"700"}} to={"/wagers_leaderboard"}>
                        <Button>Current month leaders</Button>
                    </NavLink>
                    <div style={{display:"flex", justifyContent:"center", alignItems:"center", color:"white", fontSize:"16px", fontWeight:"medium", marginBottom:"18px"}}>
                        <img style={{marginRight:"8px"}} src={roobetLogo} width="34" height="34" alt="packdraw logo"/>
                        Roobet
                    </div>
                    <h3 className="heading">Buhlockay's Last Month Wager Leaderboard</h3>
                    <h3 className="heading">{moment.unix(periodStart).tz('America/Los_Angeles').format('MMM Do YYYY hh:mm:ss A')} - {moment.unix(periodEnd).tz('America/Los_Angeles').format('MMM Do YYYY hh:mm:ss A')}</h3>
                    <p className="heading-description">Sign up using code <span className="text-highlight">
                        <a href="https://roobet.com/?ref=buhlockay" target="_blank" rel="noopener noreferrer">
                                    <span className="text-highlight">buhlockay</span>
                                </a>

                    </span> to participate</p>

                    <div className="podium-winners">
                        <div className="second-place podium-wrapper">
                            <p className="username">{wagersData[1].name}</p>
                            <p className="rank text-sm">2nd place</p>
                            <p className="wagered text-sm">Wagered <span className="amount-wagered">${wagersData[1].wager.toFixed(2)}</span></p>
                            <div className="podium">
                                <img className="second-place-chip" src={secondPlaceChip} alt="second place chip"/>
                            </div>
                            <p className="prize">${wagersData[1].wager_prize}</p>
                        </div>

                        <div className="first-place podium-wrapper">
                            <p className="username">{wagersData[0].name}</p>
                            <p className="rank text-sm">1st place</p>
                            <p className="wagered text-sm">Wagered <span className="amount-wagered">${wagersData[0].wager.toFixed(2)}</span></p>
                            <div className="podium">
                                <img className="first-place-chip" src={firstPlaceChip} alt="second place chip"/>
                            </div>
                            <p className="prize">${wagersData[0].wager_prize}</p>
                        </div>
                        <div className="third-place podium-wrapper">
                            <p className="username">{wagersData[2].name}</p>
                            <p className="rank text-sm">3rd place</p>
                            <p className="wagered text-sm">Wagered <span className="amount-wagered">${wagersData[2].wager.toFixed(2)}</span></p>
                            <div className="podium">
                                <img className="third-place-chip" src={thirdPlaceChip} alt="second place chip"/>
                            </div>
                            <p className="prize">${wagersData[2].wager_prize}</p>
                        </div>
                    </div>
                </div>
                }

                {!loading &&
                    <div className="wagers-list box-styling">

                        <table cellSpacing="0" cellPadding="0">
                            <tr>
                                <th>#</th>
                                <th>username</th>
                                <th>wagered</th>
                                <th>prize won</th>
                            </tr>
                            {
                                wagersData.map((obj, index)=>{
                                    if(index>=3){
                                        return(
                                            <tr>
                                                <td>{index + 1}</td>
                                                <td>{obj.name}</td>
                                                <td>${obj.wager.toFixed(2)}</td>
                                                <td>${obj.wager_prize}</td>
                                            </tr>
                                        )
                                    }
                                })
                            }

                        </table>
                    </div>
                }
            </div>
        </div>
    )
}