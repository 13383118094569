import './App.css';
import Header from "./components/header";
import {Outlet} from "react-router-dom";
import Footer from "./components/Footer";
import {useEffect, useState} from "react";
import React from "react";
import AuthProvider from "./AuthProvider";
import 'react-toastify/dist/ReactToastify.css';

function App() {

  return (
    <AuthProvider>
        <Header/>
        <Outlet />
        <Footer/>
    </AuthProvider>
  );
}

export default App;
