// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.userme-profile{
    margin-top:50px;
}
.profile-avatar{
    width:68px;
    height:68px;
    border-radius:8px;
}
.profile-container{
    display:flex;
    grid-column-gap: 16px;
}`, "",{"version":3,"sources":["webpack://./src/css/user-profile.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;AACA;IACI,UAAU;IACV,WAAW;IACX,iBAAiB;AACrB;AACA;IACI,YAAY;IACZ,qBAAqB;AACzB","sourcesContent":[".userme-profile{\n    margin-top:50px;\n}\n.profile-avatar{\n    width:68px;\n    height:68px;\n    border-radius:8px;\n}\n.profile-container{\n    display:flex;\n    grid-column-gap: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
