// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icon-button{
    width:44px;
    height:44px;
    display:flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background: linear-gradient(0deg, #068FFF -212.44%, rgba(6, 143, 255, 0.00) 90.93%);
    cursor:pointer;
}`, "",{"version":3,"sources":["webpack://./src/css/IconButton.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,WAAW;IACX,YAAY;IACZ,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;IAClB,mFAAmF;IACnF,cAAc;AAClB","sourcesContent":[".icon-button{\n    width:44px;\n    height:44px;\n    display:flex;\n    justify-content: center;\n    align-items: center;\n    border-radius: 8px;\n    background: linear-gradient(0deg, #068FFF -212.44%, rgba(6, 143, 255, 0.00) 90.93%);\n    cursor:pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
