import React from "react";
import "../css/wagers-leaderboard-page.css";
import firstPlaceChip from "../assets/img/1st-place.png";
import secondPlaceChip from "../assets/img/2nd-place.png";
import thirdPlaceChip from "../assets/img/3rd-place.png";
import roobetLogo from "../assets/img/roobet-logo.png";
import packdrawLogo from "../assets/img/packdraw-logo.png";
import roobetIllustration from "../assets/img/roobet-illustration.jpg";
import {useEffect, useState} from "react";
import axios from "axios";
import Loader from "../components/Loader";
import Button from "../components/Button";
import {NavLink} from "react-router-dom";
import CountDownTimer from "../components/CountDownTimer";
import {Popup} from "../components/popup";
import moment from "moment-timezone";

export default function WagersLeaderboard(){
    const wagers_api = process.env.REACT_APP_BACKEND_WAGERS;
    const packDraw_api = process.env.REACT_APP_PACKDRAW_API;
    const [wagersData, setWagersData] = useState({});
    const [loading, setLoading] = useState(true);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [getApi, setApi] = useState(false);
    const [popup, setPopup] = useState(false);

    const fetchData = async(api_link) =>{
        try{
            await axios.get(api_link)
                .then((response) => {
                    let data = response.data[1].wagers_list;
                    setWagersData(data);
                    setStartDate(response.data[0].date_info.begin_date);
                    setEndDate(response.data[0].date_info.end_date);
                });
        } catch(error){
            console.log(error);
        }
        setLoading(false);
    }


    useEffect(() => {
     fetchData(wagers_api);
    }, []);


    return(
        <div className="root page wagers-leaderboard">
            <div className="container">
                <div className="toggle-btn-container">
                    <div className="toggle-btn-wrapper">
                        <div
                            className={`toggle-btn ${getApi ? "" : "toggle-active"}`}
                            onClick={() =>{
                                if(getApi)
                                    fetchData(wagers_api);
                                setApi(false);
                            }}
                        >
                            <img src={roobetLogo} alt="roobet logo"/>
                            Roobet</div>
                        <div className={`toggle-btn ${getApi ? "toggle-active" : ""}`}
                             onClick={() =>{
                                 if(!getApi)
                                     fetchData(packDraw_api);
                                 setApi(true);
                             }}
                        >
                            <img src={packdrawLogo} alt="packdraw logo"/>
                            PackDraw</div>
                    </div>
                </div>
                {!loading && getApi ? null : <Button style={{marginBottom:"16px"}} onClick={()=> setPopup(true)}>
                        <p className="text">Roobet rules</p>
                    </Button>
                }
                {loading && <Loader/>}

                {!loading && <div className="top-3 box-styling">
                    <NavLink style={{fontWeight:"700"}} to={getApi ? "/last_month_wagers_packdraw" : "/last_month_wagers_leaderboard"}>
                        <Button>Last month winners</Button>
                    </NavLink>


                    <h3 className="heading">Buhlockay's Wager Leaderboard</h3>
                    <p className="heading-description">Sign up using code <span className="text-highlight">
                        <a href="https://roobet.com/?ref=buhlockay" target="_blank" rel="noopener noreferrer">
                                    <span className="text-highlight">buhlockay</span>
                                </a>

                    </span> to participate</p>
                    {/*<h5 className="heading">We are moving to another casino. Wait for a while!</h5>*/}
                    <h3 className="heading">
                        Ends in
                        <CountDownTimer end_time={endDate}/>
                        <span style={{fontSize:"16px", color:"var(--slate-400)", fontWeight:"400"}}>by {moment.unix(endDate).tz('America/Los_Angeles').format('MMM Do YYYY hh:mm:ss A z')}</span>
                    </h3>

                    <Popup
                        isRevealed={popup}
                        header="Rules"
                        body={<Rules/>}
                        controls={<Button onClick={()=> setPopup(false)}>
                            <p className="text">Got it</p>
                        </Button>}
                        close={()=> setPopup(false)}
                    />
                    <div className="podium-winners">
                        <div className="second-place podium-wrapper">
                            <p className="username">{wagersData.length ? wagersData[1]?.name : null}</p>
                            <p className="rank text-sm">2nd place</p>
                            {wagersData.length ?
                            <p className="wagered text-sm">Wagered <span className="amount-wagered">${wagersData[1].wager.toFixed(2)}</span></p>
                            : null
                        }

                            <div className="podium">
                                <img className="second-place-chip" src={secondPlaceChip} alt="second place chip"/>
                            </div>
                            <p className="prize">{wagersData.length ? `$ ${wagersData[1]?.wager_prize}` : null}</p>
                        </div>

                        <div className="first-place podium-wrapper">
                            <p className="username">{wagersData.length ? wagersData[0]?.name : null}</p>
                            <p className="rank text-sm">1st place</p>
                            {wagersData.length ?
                            <p className="wagered text-sm">Wagered <span className="amount-wagered">${wagersData[0].wager.toFixed(2)}</span></p>
                            : null
                        }

                            <div className="podium">
                                <img className="first-place-chip" src={firstPlaceChip} alt="second place chip"/>
                            </div>
                            <p className="prize">{wagersData.length ?  `$ ${wagersData[0]?.wager_prize}` : null}</p>
                        </div>
                        <div className="third-place podium-wrapper">
                            <p className="username">{wagersData.length ? wagersData[2]?.name : null}</p>
                            <p className="rank text-sm">3rd place</p>
                            {wagersData.length ?
                            <p className="wagered text-sm">Wagered <span className="amount-wagered">${wagersData[2].wager.toFixed(2)}</span></p>
                            : null
                        }

                            <div className="podium">
                                <img className="third-place-chip" src={thirdPlaceChip} alt="second place chip"/>
                            </div>
                            <p className="prize">{wagersData.length ? `$ ${wagersData[2]?.wager_prize}` : null}</p>
                        </div>
                    </div>
                </div>
                }

                {!loading &&
                <div className="wagers-list box-styling">
                    <table cellSpacing="0" cellPadding="0">
                        <tr>
                            <th>#</th>
                            <th>username</th>
                            <th>wagered</th>
                            <th>prize won</th>
                        </tr>
                        {
                            wagersData.length ? wagersData.map((obj, index)=>{
                                if(index>=3){
                                    return(
                                        <tr>
                                            <td>{index + 1}</td>
                                            <td>{obj?.name}</td>
                                            <td>${obj?.wager.toFixed(2)}</td>
                                            <td>${obj?.wager_prize}</td>
                                        </tr>
                                    )
                                }
                            }) : null
                        }

                    </table>
                </div>
                }
            </div>
        </div>
    )
}

function Rules(){
    return <div style={{fontSize:"15px"}}>
        <img
            style={{width:"100%", borderRadius:"12px"}}
            src={roobetIllustration} alt="roobet illustration"/>
        <p style={{margin:"14px 0"}}>Your wagers on Roobet will count towards the leaderboard at the
            following weights based on the games you are playing. This helps
            prevent leaderboard abuse:
        </p>
        <ul style={{listStyleType:"circle"}}>
            <li>Games with an RTP of 97% or less will contribute 100% of the
                amount wagered to the leaderboard.</li>
            <li>Games with an RTP above 97% will contribute 50% of the amount
                wagered to the leaderboard.</li>
            <li>Games with an RTP of 98% and above will contribute 10% of the
                amount wagered to the leaderboard.</li>
            <li>Only Slots and Housegames count (dice is excluded).</li>
        </ul>
    </div>
}