import "../css/footer.css";
import { ReactComponent as KickWhite } from '../assets/icons/kick-white.svg';
import { ReactComponent as Discord } from '../assets/icons/discord.svg';
import { ReactComponent as Youtube } from '../assets/icons/youtube.svg';
import { ReactComponent as Twitter } from '../assets/icons/twitter.svg';
import IconButton from "./IconButton";
export default function Footer(){
    return(
        <footer className="container">
            <div className="socials-icons">
                <a href="https://kick.com/buhlockay" target="_blank" rel="noreferrer">
                    <IconButton>
                        <KickWhite/>
                    </IconButton>
                </a>
                <a href="https://discord.com/invite/bJRY2weub4" target="_blank" rel="noreferrer">
                    <IconButton>
                        <Discord/>
                    </IconButton>
                </a>
                <a href="https://www.youtube.com/@buhlockay_" target="_blank" rel="noreferrer">
                    <IconButton>
                        <Youtube/>
                    </IconButton>
                </a>
                <a href="https://twitter.com/buhlockay_" target="_blank" rel="noreferrer">
                    <IconButton>
                        <Twitter/>
                    </IconButton>
                </a>

            </div>
            <p>Buhlockay</p>
            <p>&#169;
                {(new Date().getFullYear())} All rights reserved
            </p>

        </footer>
    )
}