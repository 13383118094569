import heroImage from "../assets/img/buhlockay-hero-image.png";
import "../css/main-page.css";
import followMeFont from "../assets/img/follow-me-font.png";
import rewardsFont from "../assets/img/reward-font.png";
import joinUsFont from "../assets/img/join-us-font.png";
import IconButton from "../components/IconButton";
import pokerChip from "../assets/img/pokerchip.png";
import { ReactComponent as KickWhite } from '../assets/icons/kick-white.svg';
import { ReactComponent as Discord } from '../assets/icons/discord.svg';
import { ReactComponent as Youtube } from '../assets/icons/youtube.svg';
import { ReactComponent as Twitter } from '../assets/icons/twitter.svg';
import { ReactComponent as YoutubeRed } from "../assets/icons/youtube_red.svg";
import { ReactComponent as Eye } from "../assets/icons/eye.svg";
import {useEffect, useState} from "react";
import axios from "axios";
import Loader from "../components/Loader";

export default function Main(){
    const [youtubeVideos, setYoutubeVideos ] = useState([]);
    const youtube_api = process.env.REACT_APP_BACKEND_YOUTUBE_API;
    const [loading, setLoading] = useState(true);

    const fetchData = async()=>{
        try{
            await axios.get(youtube_api)
                .then(function(response){
                    setYoutubeVideos(response.data);
                })
        }
        catch(e){

        }
        setLoading(false);
    }

    useEffect(() => {
        fetchData();
    }, []);

    return(
        <div className="root page">
            <div className="container hero-image-container">
                <img className="hero-image" src={heroImage} alt="hero-image"/>
            </div>

            <div className="container hero-section-info">

                    <h3 className="heading">Entertaining content & rewards</h3>
                    <ul className="newsfeed-info">
                        <li className="rewards-cardinfo">
                            <div className="circle-wrapper">
                                <img className="poker-chip" src={pokerChip} alt="poker chip"/>
                            </div>
                            <img className="rewards-font-png" src={rewardsFont} alt="rewards-font"/>
                            <p>
                                Join the BEST Casino with the
                                <span className="text-highlight">Best Rewards!</span>
                            </p>
                        </li>
                        <li className="followme-cardinfo">
                            <div className="circle-wrapper">
                                <img className="poker-chip" src={pokerChip} alt="poker chip"/>
                            </div>
                            <img className="followme-font-png" src={followMeFont} alt="followme-font"/>
                            <div className="socials-icons">
                                <a href="https://kick.com/buhlockay" target="_blank" rel="noreferrer">
                                    <IconButton>
                                        <KickWhite/>
                                    </IconButton>
                                </a>
                                <a href="https://discord.com/invite/bJRY2weub4" target="_blank" rel="noreferrer">
                                    <IconButton>
                                        <Discord/>
                                    </IconButton>
                                </a>
                                <a href="https://www.youtube.com/@buhlockay_" target="_blank" rel="noreferrer">
                                    <IconButton>
                                        <Youtube/>
                                    </IconButton>
                                </a>
                                <a href="https://twitter.com/buhlockay_" target="_blank" rel="noreferrer">
                                    <IconButton>
                                        <Twitter/>
                                    </IconButton>
                                </a>

                            </div>
                        </li>
                        <li className="joinus-card-info">
                            <div className="circle-wrapper">
                                <img className="poker-chip" src={pokerChip} alt="poker chip"/>
                            </div>
                            <img className="joinus-font-png" src={joinUsFont} alt="joinus-font"/>
                            <p>
                                <a href="https://discord.gg/buhlockay" target="_blank" rel="noopener noreferrer">
                                    <span className="text-highlight">Join the discord</span>
                                </a>
                                to stay up to date on latest news, giveaways and prizes.
                            </p>
                        </li>
                    </ul>
            </div>

            <div className="container youtube-highlights">
                <h4 className="heading">
                    <YoutubeRed/>
                    Youtube highlights

                </h4>

                <div className="section-content youtube-contents">
                    {loading && <Loader/>}
                    {

                        !loading && youtubeVideos.map((item, index) =>{
                            if(index <=2){
                                return(
                                    <div className="youtube-video-wrapper" key={index}>
                                        <a href={`https://www.youtube.com/watch?v=${item.videoId}`}
                                           className="youtube-video-container" key={index}
                                           target="_blank"
                                           rel="noreferrer">
                                            <img className="thumbnail" src={item.thumbnail?.url} alt={item.thumbnail?.url ? "youtube thumbnail" : "no image"}/>
                                            {/*<div className="thumbnail" style={{backgroundImage:`url(${item.thumbnail.url})`, backgroundAttachment:"fixed", backgroundRepeat:"no-repeat", backgroundSize:"cover"}}>*/}

                                            {/*</div>*/}
                                            <div className="video-description">
                                                <p className="title">{item.title}</p>
                                                <div className="viewers-wrapper">
                                                    <Eye/>
                                                    <span className="viewers-count">{item.viewCount}</span>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                )
                            }
                        })
                    }
                </div>
            </div>
        </div>
    )
}