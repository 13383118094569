import "../css/Button.css";

export default function Button(props){
    if(props.btn_style ==="primary" || !props.btn_style){
        return(
            <button onClick={props.onClick} {...props}>
                {props.children}
            </button>
        )
    }
    if(props.btn_style === "error"){
        return(
            <button className={`${props.className} error`} onClick={props.onClick} {...props}>
                {props.children}
            </button>
        )
    }

    if(props.btn_style === "success"){
        return(
            <button className={`${props.className} success`} onClick={props.onClick} {...props}>
                {props.children}
            </button>
        )
    }
}