import React, {useState} from "react";
import "../css/popup.css";

export function Popup(props){

    return props.isRevealed ?
    <div className="popup">
        <div className="popup__main">
            <div className="popup__header">{props.header}</div>
            <div className="popup__body">
                {props.body}
            </div>
            <div className="popup__controls">
                {props.controls}
            </div>
        </div>
        <div className="popup__background" onClick={props.close}>

        </div>
    </div>
        :
        null
}