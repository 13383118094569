import {useContext, createContext, useState, useEffect} from "react";
import axios from "axios";

import { useNavigate } from "react-router-dom";

const AuthContext = createContext();

export default function AuthProvider({children}){
    const buhlockay_api = process.env.REACT_APP_BACKEND_API;

    const navigate = useNavigate();
    const [username, setUsername] = useState(null);
    const [avatar, setAvatar] = useState(null);
    const [token, setToken] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [role, setRole] = useState(null);

    // check if user has token
    // when navigating to protected routes, check user token, if its not found, then redirect to the main page


    const getProfile = async() => {

        await axios.get(`${buhlockay_api}/api/userme`, {withCredentials:true})
            .then((response) =>{
                setUsername(response.data[0].name);
                setAvatar("https://cdn.discordapp.com/avatars/" + response.data[0].user_id + "/" + response.data[0].avatar);
                setLoading(false);
                setRole(response.data[0].role);
                // setUser(response.data[0].name);
            })
            .catch(function(error){
                if(error.response){

                }
                else if(error.request){

                }
                else{

                }
            })

    }


    const logOut = async() =>{
        try{
            await axios.get(`${buhlockay_api}/api/logout`,{withCredentials:true})
                .then((response) =>{

                    setUsername(null);
                    setAvatar(null);
                    setLoading(null);

                    navigate("/");
                })
                .catch(function(error){
                    if(error.response){
                        console.log(error.response.data);
                    }
                    else if(error.request){
                        console.log(error.request);
                    }
                    else{
                        console.log('error', error.message);
                    }
                })
        }
        catch(error){
            console.log(error);
        }
    }
    const user = {
        username: username,
        avatar: avatar,
        token: token,
        isLoading: isLoading,
        role:role
    }
    useEffect(() =>{

        getProfile();

    },[])

    return <AuthContext.Provider value={{ user, logOut }}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
    return useContext(AuthContext);
};