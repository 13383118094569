import {NavLink, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import axios from "axios";
import Loader from "../components/Loader";
import "../css/bonushuntgame.css";
import secondPlaceChip from "../assets/img/2nd-place.png";
import firstPlaceChip from "../assets/img/1st-place.png";
import thirdPlaceChip from "../assets/img/3rd-place.png";
import Button from "../components/Button";
import {ToastContainer, toast} from "react-toastify";
import {toast_options} from "../components/toast";
import {useAuth} from "../AuthProvider";
import { useNavigate } from "react-router-dom";
export default function BonusHuntGame({match}){
    const buhlockay_api = process.env.REACT_APP_BACKEND_API;
    const {user} = useAuth();
    const {huntId} = useParams();
    const [info, setInfo] = useState(null);
    const [loading, setLoading] = useState(true);
    const [huntEnabled, setHuntEnabled] = useState(null);
    const [isPublished, setIsPublished] = useState(null);
    const [showWinners, setShowWinners] = useState(false);
    const [loadingToggleBtn, setLoadingToggleBtn] = useState(false);
    const navigate = useNavigate();
    const addAndUpdateGame = async() =>{

            const bhg_data = await axios.get('https://bht.bet/api/hf8EXkX0UbkaB4kgF8FzciDVdNTHMLO1/hunts/' + huntId);

            await axios.post(`${buhlockay_api}/api/admin/add-hunt`, {bhg_info: Object.assign(bhg_data.data, {isEnabled:false})},
                    {headers: {'Content-Type': 'application/json',
                        }, withCredentials:true})
                    .then((response) =>{
                    });

            await axios.get(`${buhlockay_api}/api/admin/hunts/` + huntId,{withCredentials: true})
            .then(response =>{
                setInfo(response.data[0]);
                setHuntEnabled(response.data[0].is_enabled);
                setIsPublished(response.data[0].is_published);
                setShowWinners(response.data[0].first_place || response.data[0].second_place || response.data[0].third_place);
                setLoading(false);

            })
            .then(error =>{

            })

    }

    const postWinners = async () =>{
        setLoadingToggleBtn(true);
        await addAndUpdateGame();
        try{
            await axios.post(`${buhlockay_api}/api/admin/post-winners`, {hunt_id: huntId}, {withCredentials:true})
                .then(response =>{

                    setInfo((prevState) =>{
                        return({
                            ...prevState,
                            first_place:response.data[0],
                            second_place: response.data[1],
                            third_place: response.data[2],
                            participants: response.data
                        })
                    })
                    setShowWinners(true);
                    toast("Found the winners!", toast_options);
                })
                .catch(error =>{
                    if(error.response) {
                        toast.error(error.response.data.msg, toast_options);
                    } else if (error.request) {
                        toast.error(error.request, toast_options);
                    } else {
                        toast.error(error.message, toast_options);
                    }
                    toast.error(error.config,toast_options);
                })
        }
        catch(error){
            console.log(error);
        }
        setLoadingToggleBtn(false);
    }

    useEffect(() =>{
        // if(user.role !== 2){
        //     navigate("/");
        // }

        addAndUpdateGame();
    },[]);


    if(!huntId){
        return (
            <div className="container bhg-game-info">>
                Not found
            </div>
        )
    }
    const hasFinalBalance = () =>{
        return info.info_amount_won !== "-";
    }


    const enableGTBBtn = () =>{
        return(
            <Button
                btn_style={huntEnabled ? "error" : "success"}
            onClick={()=>{
                if(huntEnabled){
                    axios.post(`${buhlockay_api}/api/admin/disable-hunt`, {id:info.hunt_id, is_enabled: false}, {withCredentials:true})
                        .then(response =>{

                            setHuntEnabled(response.data.is_enabled);
                            toast("The hunt is disabled", toast_options);
                        })
                        .catch(error =>{

                            toast.error(error.response.data.msg, toast_options);
                        })

                }
                else{
                    axios.post(`${buhlockay_api}/api/admin/enable-hunt`, {id:info.hunt_id, is_enabled: true}, {withCredentials:true})
                            .then(response =>{
                                setHuntEnabled(response.data.is_enabled);
                                toast("The hunt is enabled", toast_options);
                            })
                            .catch(error =>{
                                if (error.response) {
                                    toast.error(error.response.data.msg, toast_options);
                                } else if (error.request) {
                                    toast.error(error.request, toast_options);
                                } else {
                                    toast.error(error.message, toast_options);
                                }
                                toast.error(error.config,toast_options);
                            })
                }

            }}>
                {huntEnabled ? "Disable hunt" : "Enable hunt"}
            </Button>
        )
    }

    return(
        <div className="container bhg-game-info">
            {loading && <Loader/>}


                <ToastContainer />

            {!loading &&
                <div style={{display:'flex', columnGap:"16px"}}>
            <div className="panel-container" style={{width:"100%"}}>
                <div className="panel-container-header">
                    [{info.hunt_id}] {info.name}

                    <div style={{display:"flex", gridColumnGap:"10px"}}>
                        {
                            enableGTBBtn()
                        }
                        <Button onClick={async ()=>{
                            await axios.post(`${buhlockay_api}/api/admin/publish-hunt`, {id: info.hunt_id, is_published: true}, {withCredentials: true})
                                .then(response =>{
                                    setIsPublished(response.data.is_published);
                                    toast.success("The hunt has been published", toast_options);
                                })
                                .catch(error =>{
                                    if (error.response) {
                                        toast.error(error.response.data.msg, toast_options);
                                    } else if (error.request) {
                                        toast.error(error.request, toast_options);
                                    } else {
                                        toast.error(error.message, toast_options);
                                    }
                                    toast.error(error.config,toast_options);
                                })
                        }}>
                            Publish
                        </Button>

                    </div>
                </div>
                <div className="panel-container-body bhg">
                    <div>


                        <p>Final Balance: {info.info_amount_won}</p>
                        {
                            info.info_amount_won !== "-" ?
                                <Button onClick={async(e)=>{
                                    postWinners();

                                }}>
                                    {loadingToggleBtn ? <Loader style={{height:"20px", width:'20px'}}/> : null}
                                    Find winners</Button> :
                                <Button disabled>Find winners</Button>
                        }
                    </div>

                    <div className="">
                        {
                            showWinners ?
                                <div className="winners" style={{marginTop:"30px"}}>
                                    <h5>Top 3 winners</h5>

                                    <div className="podium-winners">
                                        <div className="second-place podium-wrapper">
                                            <h6 className="found-winner">{info.second_place ? info.second_place.kick_name : "-"}</h6>
                                            <p className="rank text-sm">2nd place</p>
                                            <p className="wagered text-sm">Guessed:  <span className="amount-wagered">$ {info.second_place ? info.second_place.guessed_balance : "-"}</span></p>
                                            <div className="podium">
                                                <img className="second-place-chip" src={secondPlaceChip} alt="second place chip"/>
                                            </div>
                                            <p className="prize"></p>
                                        </div>

                                        <div className="first-place podium-wrapper">
                                            <h6 className="found-winner">{info.first_place ? info.first_place.kick_name : "-"}</h6>
                                            <p className="rank text-sm">1st place</p>
                                            <p className="wagered text-sm">Guessed: <span className="amount-wagered">$ {info.first_place ? info.first_place.guessed_balance : "-"}</span></p>
                                            <div className="podium">
                                                <img className="first-place-chip" src={firstPlaceChip} alt="second place chip"/>
                                            </div>
                                            <p className="prize"></p>
                                        </div>
                                        <div className="third-place podium-wrapper">
                                            <h6 className="found-winner">{info.third_place ? info.third_place.kick_name :"-"}</h6>
                                            <p className="rank text-sm">3rd place</p>
                                            <p className="wagered text-sm">Guessed: <span className="amount-wagered">$ {info.third_place ? info.third_place.guessed_balance : "-"}</span></p>
                                            <div className="podium">
                                                <img className="third-place-chip" src={thirdPlaceChip} alt="second place chip"/>
                                            </div>
                                            <p className="prize"></p>
                                        </div>
                                    </div>
                                </div>
                                :
                                null
                        }



                    </div>
                </div>
            </div>
                    <div className="panel-container" style={{width:"100%"}}>
                        <div className="panel-container-header">Participants</div>
                        <div className="participants" style={{overflow:"auto"}}>

                            {
                                info.participants.length !== 0 ? <table cellSpacing="0" cellPadding="0">
                                        <tr>
                                            <th style={{width:"30%"}}>Discord username</th>
                                            <th>Kick username</th>
                                            <th>Guessed balance</th>
                                        </tr>
                                        {
                                            info.participants.map((participant, index)=>{
                                                return(
                                                    <tr key={index}>
                                                        <td>{participant.user?.name}</td>
                                                        <td>{participant.kick_name}</td>
                                                        <td>$ {participant.guessed_balance}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </table>
                                    :
                                    <table>
                                        <tr>
                                            <th>Discord username</th>
                                            <th>Kick username</th>
                                            <th>Guessed balance</th>
                                        </tr>
                                        <tr>
                                            <td>
                                                <td>no participants have joined yet</td>
                                                <td></td>
                                                <td></td>
                                            </td>
                                        </tr>
                                    </table>

                            }
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}