import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {createBrowserRouter, Navigate, RouterProvider} from "react-router-dom";
import WagersLeaderboard from "./routes/WagersLeaderboard";
import Main from "./routes/main";
import LastMonthWagersLeaderboard from "./routes/LastMonthWagersLeaderboard";
import Rewards from "./routes/Rewards";
import BonusHunt from "./routes/BonusHunt";
import AdminPage from "./routes/admin";
import UserMeProfile from "./routes/userMeProfile";
import BonusHuntGame from "./routes/BonusHuntGame";
import LastMonthPackDrawWagers from "./routes/LastMonthPackDrawWagers";

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        children:[
            {
                index: true,
                element: <Main/>
            },
            {
                path:"/wagers_leaderboard",
                element:<WagersLeaderboard/>
            },
            {
                path:"/last_month_wagers_leaderboard",
                element:<LastMonthWagersLeaderboard/>
            },
            {
                path:"/rewards",
                element:<Rewards/>
            },
            {
                path:"/bonus_hunt",
                element:<BonusHunt/>
            },
            {
                path:"/admin",
                element: <AdminPage/>,
            },
            {
                path:"admin/:huntId",
                element:<BonusHuntGame/>
            },
            {
                path:"/me",
                element:<UserMeProfile/>
            },
            {
                path:"/last_month_wagers_packdraw",
                element:<LastMonthPackDrawWagers/>
            }
        ]
    },
]);
const root = ReactDOM.createRoot(document.getElementById('root'));

// const theme = createTheme({
//     /** Put your mantine theme override here */
// });

root.render(
    // <MantineProvider theme={theme}>
    //     <Notifications />
        <RouterProvider router={router}/>
    // </MantineProvider>
);
